dmx.Component('google-maps', {

    initialData: {
        zoom: 10,
        maptype: 'roadmap',
        latitude: null,
        longitude: null
    },

    attributes: {
        width: {
            type: [String, Number],
            default: '100%'
        },

        height: {
            type: [String, Number],
            default: 400
        },

        latitude: {
            type: Number,
            default: null
        },

        longitude: {
            type: Number,
            default: null
        },

        address: {
            type: String,
            default: null
        },

        /*
          Zoom Levels
          1: World
          5: Landmass/continent
          10: City
          15: Streets
          20: Buildings
        */
        zoom: {
            type: Number,
            default: 10
        },

        maptype: {
            type: String,
            default: 'roadmap' // roadmap, satellite, hybrid, terrain
        },

        scrollwheel: {
            type: Boolean,
            default: false
        },

        tilt: {
            type: Boolean,
            default: false
        },

        'rotate-control': {
            type: Boolean,
            default: false
        },

        'scale-control': {
            type: Boolean,
            default: false
        },

        'fullscreen-control': {
            type: Boolean,
            default: false
        },

        'zoom-control': {
            type: Boolean,
            default: false
        },

        'streetview-control': {
            type: Boolean,
            default: false
        },

        'maptype-control': {
            type: Boolean,
            default: false
        },

        'enable-clusters': {
            type: Boolean,
            default: false
        },

        'traffic-layer': {
            type: Boolean,
            default: false
        },

        'transit-layer': {
            type: Boolean,
            default: false
        },

        'bicycling-layer': {
            type: Boolean,
            default: false
        },

        markers: {
            type: Array,
            default: null
        },

        'marker-id': {
            type: String,
            default: 'id'
        },

        'marker-latitude': {
            type: String,
            default: 'latitude'
        },

        'marker-longitude': {
            type: String,
            default: 'longitude'
        },

        'marker-address': {
            type: String,
            default: 'address'
        },

        'marker-label': {
            type: String,
            default: 'label'
        },

        'marker-label-color': {
            type: String,
            default: 'label'
        },

        'marker-title': {
            type: String,
            default: 'title'
        },

        'marker-info': {
            type: String,
            default: 'info'
        },

        'marker-type': {
            type: String,
            default: 'type'
        },

        'marker-image': {
            type: String,
            default: 'type'
        },

        'marker-animation': {
            type: String,
            default: 'animation' // bounce and drop
        },

        'marker-draggable': {
            type: String,
            default: 'draggable'
        }
    },

    methods: {
        addMarker: function(options) {
            var marker = this.addMarker(options);
            if (this.cluster && this.props['enable-clusters']) {
                this.cluster.addMarker(marker);
            } else {
                marker.setMap(this.map);
            }
        },

        goToMarker: function(id) {
            var marker = this.findMarker(id);
            if (marker) {
                this.map.setCenter(marker.position);
            }
        },

        panToMarker: function(id) {
            var marker = this.findMarker(id);
            if (marker) {
                this.map.panTo(marker.position);
            }
        },

        bounceMarker: function(id) {
            var marker = this.findMarker(id);
            if (marker) {
                marker.setAnimation(1);
            }
        },

        fitBoundsToMarkers: function() {
            if (this.markers.length) {
                var bounds = new google.maps.LatLngBounds();

                for (var i = 0; i < this.markers.length; i++) {
                    bounds.extend(this.markers[i].getPosition());
                }

                this.map.fitBounds(bounds);
            }
        },

        stopBounce: function(id) {
            var marker = this.findMarker(id);
            if (marker) {
                marker.setAnimation(null);
            }
        },

        showInfo: function(id) {
            var marker = this.findMarker(id);
            if (marker && marker.info) {
                this.openInfoWindow(marker, marker.info);
            }
        },

        removeAllMarkers: function() {
            this.removeAllMarkers();
        },

        panTo: function(latitude, longitude) {
            this.map.panTo({ lat: +latitude, lng: +longitude });
        },

        setCenter: function(latitude, longitude) {
            this.map.setCenter({ lat: +latitude, lng: +longitude });
        },

        setMapType: function(maptype) {
            this.map.setMapTypeId(maptype);
        },

        setZoom: function(zoom) {
            this.map.setZoom(zoom);
        },

        refresh: function() {
            google.maps.event.trigger(this.map, 'resize');
        },

        reload: function() {
            this.update({});
        }
    },

    events: {
        ready: Event,
        boundschanged: Event,
        centerchanged: Event,
        maptypechanged: Event,
        zoomchanged: Event,
        mapclick: Event,
        markerclick: Event,
        markerpositionchanged: Event
    },

    render: function(node) {
        this.$parse();

        node.style.display = 'block';
        node.style.width = this.getSize(this.props.width);
        node.style.height = this.getSize(this.props.height);

        this.geocodeCache = JSON.parse(localStorage.geocodeCache || '{}');
        this.geocoder = new google.maps.Geocoder();
        this.infoWindow = new google.maps.InfoWindow();

        this.markers = [];
        this.getMarkerTypes();

        this.map = new google.maps.Map(node, {
            zoom: +this.props.zoom,
            center: { lat: +this.props.latitude, lng: +this.props.longitude },
            mapTypeId: this.props.maptype,
            scrollwheel: this.props.scrollwheel,
            scaleControl: this.props['scale-control'],
            zoomControl: this.props['zoom-control'],
            panControl: this.props['pan-control'],
            streetViewControl: this.props['streetview-control'],
            mapTypeControl: this.props['maptype-control'],
            rotateControl: this.props['rotate-control'],
            fullscreenControl: this.props['fullscreen-control']
        });

        this.set('latitude', +this.props.latitude);
        this.set('longitude', +this.props.longitude);
        this.set('maptype', this.map.getMapTypeId());
        this.set('zoom', this.map.getZoom());

        this.map.addListener('click', this.onMapClick.bind(this));
        this.map.addListener('bounds_changed', dmx.debounce(this.onBoundsChanged.bind(this), 100));
        this.map.addListener('center_changed', dmx.debounce(this.onCenterChanged.bind(this), 100));
        this.map.addListener('maptypeid_changed', dmx.debounce(this.onMaptypeChanged.bind(this), 100));
        this.map.addListener('zoom_changed', dmx.debounce(this.onZoomChanged.bind(this), 100));
        setTimeout(this.dispatchEvent.bind(this, 'ready'), 100);

        if (window.googleMapsTheme) {
            this.map.setOptions({ styles: window.googleMapsTheme });
        }

        if (this.props.tilt) {
            this.map.setTilt(45);
        }

        this.getMarkers();

        if (this.props['enable-clusters']) {
            this.cluster = new MarkerClusterer(this.map, this.markers, {
                imagePath: this.getImageFolder()
            });
        }

        if (!(this.props.latitude && this.props.longitude) && this.props.address) {
            this.geocode(this.props.address);
        }

        if (this.props['traffic-layer']) {
            this.trafficLayer = new google.maps.TrafficLayer();
            this.trafficLayer.setMap(this.map);
        }

        if (this.props['transit-layer']) {
            this.transitLayer = new google.maps.TransitLayer();
            this.transitLayer.setMap(this.map);
        }

        if (this.props['bicycling-layer']) {
            this.bikeLayer = new google.maps.BicyclingLayer();
            this.bikeLayer.setMap(this.map);
        }
    },

    update: function(props) {
        if (this.props.latitude != props.latitude || this.props.longitude != props.longitude) {
            this.map.setCenter({ lat: +this.props.latitude, lng: +this.props.longitude });
        }

        if (this.props.address && this.props.address != props.address) {
            this.geocode(this.props.address);
        }

        if (this.props.zoom != props.zoom) {
            this.map.setZoom(+this.props.zoom);
        }

        if (this.props.maptype != props.maptype) {
            this.map.setMapTypeId(this.props.maptype);
        }

        if (this.props.tilt != props.tilt) {
            this.map.setTilt(this.props.tilt ? 45 : 0);
        }

        if (JSON.stringify(this.props.markers) != JSON.stringify(props.markers)) {
            this.removeAllMarkers();

            var markers = dmx.repeatItems(this.props.markers);

            if (Array.isArray(markers)) {
                markers.forEach(function(marker) {
                    // Normally the current component is the parent scope
                    // in this case we take the parent since it causes some
                    // problems when latitude/longitude is missing from the
                    // marker, it then uses the latitude/longitude from the
                    // map which it shouldn't
                    var scope = new dmx.DataScope(marker, this.parent);
                    this.addMarker({
                        id: dmx.parse(this.props['marker-id'], scope),
                        latitude: +dmx.parse(this.props['marker-latitude'], scope),
                        longitude: +dmx.parse(this.props['marker-longitude'], scope),
                        address: dmx.parse(this.props['marker-address'], scope),
                        label: dmx.parse(this.props['marker-label'], scope),
                        labelColor: dmx.parse(this.props['marker-label-color'], scope),
                        title: dmx.parse(this.props['marker-title'], scope),
                        info: dmx.parse(this.props['marker-info'], scope),
                        type: dmx.parse(this.props['marker-type'], scope),
                        image: dmx.parse(this.props['marker-image'], scope),
                        animation: dmx.parse(this.props['marker-animation'], scope),
                        draggable: !!dmx.parse(this.props['marker-draggable'], scope)
                    });
                }, this);

                if (this.props['enable-clusters']) {
                    this.cluster = new MarkerClusterer(this.map, this.markers, {
                        imagePath: this.getImageFolder()
                    });
                }
            }
        }

        if (JSON.stringify(this.props) != JSON.stringify(props)) {
            this.map.setOptions({
                scrollwheel: this.props.scrollwheel,
                scaleControl: this.props['scale-control'],
                zoomControl: this.props['zoom-control'],
                panControl: this.props['pan-control'],
                streetViewControl: this.props['streetview-control'],
                mapTypeControl: this.props['maptype-control'],
                rotateControl: this.props['rotate-control'],
                fullscreenControl: this.props['fullscreen-control']
            });
        }

        if (this.props['traffic-layer'] != props['traffic-layer']) {
            this.trafficLayer = this.trafficLayer || new google.maps.TrafficLayer();
            this.trafficLayer.setMap(this.props['traffic-layer'] ? this.map : null);
        }

        if (this.props['transit-layer'] != props['transit-layer']) {
            this.transitLayer = this.transitLayer || new google.maps.TransitLayer();
            this.transitLayer.setMap(this.props['transit-layer'] ? this.map : null);
        }

        if (this.props['bicycling-layer'] != props['bicycling-layer']) {
            this.bikeLayer = this.bikeLayer || new google.maps.BicyclingLayer();
            this.bikeLayer.setMap(this.props['bicycling-layer'] ? this.map : null);
        }
    },

    onMapClick: function(e) {
        this.dispatchEvent('mapclick', null, { latitude: e.latLng.lat(), longitude: e.latLng.lng(), position: e.latLng.toJSON() });
    },

    onBoundsChanged: function() {
        this.dispatchEvent('boundschanged');
    },

    onCenterChanged: function() {
        var center = this.map.getCenter();
        this.set('latitude', center.lat());
        this.set('longitude', center.lng());
        this.dispatchEvent('centerchanged');
    },

    onMaptypeChanged: function() {
        this.set('maptype', this.map.getMapTypeId());
        this.dispatchEvent('maptypechanged');
    },

    onZoomChanged: function() {
        this.set('zoom', this.map.getZoom());
        this.dispatchEvent('zoomchanged');
    },

    onMarkerClick: function(marker) {
        this.dispatchEvent('markerclick', null, { id: marker.id });
    },

    onMarkerPositionChanged: function(marker) {
        this.dispatchEvent('markerpositionchanged', null, { latitude: marker.position.lat(), longitude: marker.position.lng(), position: marker.position.toJSON() });
    },

    geocode: function(address) {
        if (address) {
            if (this.geocodeCache[address]) {
                this.map.setCenter(this.geocodeCache[address]);
            } else {
                this.geocoder.geocode({ address: this.props.address }, function(results, status) {
                    if (status == 'OK') {
                        this.geocodeCache[address] = results[0].geometry.location;
                        this.map.setCenter(this.geocodeCache[address]);
                        localStorage.geocodeCache = JSON.stringify(this.geocodeCache);
                    } else {
                        console.warn('Geocode was not successful for the following reason: ' + status)
                    }
                }.bind(this));
            }
        }
    },

    openInfoWindow: function(marker, content) {
        this.infoWindow.setContent(content);
        this.infoWindow.open(this.map, marker);
    },

    getSize: function(size) {
        if (typeof size == 'string' && size.slice(-1) == '%') {
            return size;
        } else {
            return parseInt(size, 10) + 'px';
        }
    },

    getImageFolder: function() {
        var script = document.querySelector('script[src$="dmxGoogleMaps.js"]');
        if (script && script.src) {
            return script.src.replace(/dmxGoogleMaps.js$/, 'images/m');
        }
        return 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m';
    },

    getMarkerAnimation: function(animation) {
        switch (animation.toLowerCase()) {
            case 'bounce': return 1;
            case 'drop': return 2;
        }

        return null;
    },

    findMarker: function(id) {
        return this.markers.find(function(marker) {
            return marker.id == id;
        });
    },

    getMarkers: function(node) {
        for (var i = 0; i < this.children.length; i++) {
            var child = this.children[i];

            if (child instanceof dmx.Component('google-maps-marker')) {
                child.marker = this.addMarker({
                    static: true,
                    id: child.name,
                    latitude: +child.props.latitude,
                    longitude: +child.props.longitude,
                    address: child.props.address,
                    label: child.props.label,
                    labelColor: child.props['label-color'],
                    title: child.props.title,
                    info: child.props.info,
                    type: child.props.type,
                    image: child.props.image,
                    animation: child.props.animation,
                    draggable: !!child.props.draggable
                });
            }
        }
    },

    addMarker: function(options) {
        var marker = new google.maps.Marker({
            static: options.static || false,
            position: { lat: +options.latitude, lng: +options.longitude },
            label: options.label,
            title: options.title,
            icon: this.markerTypes[options.type],
            draggable: options.draggable
        });

        if (options.id) {
            marker.id = options.id;
        }

        if (options.image) {
            marker.setIcon(options.image);
        }

        if (options.label && options.labelColor) {
            marker.setLabel({
                color: options.labelColor,
                text: options.label
            });
        }

        if (options.info) {
            marker.info = options.info;
            marker.addListener('click', this.openInfoWindow.bind(this, marker, options.info));
        }

        if (options.animation) {
            marker.setAnimation(this.getMarkerAnimation(options.animation));
        }

        if (!(options.latitude && options.longitude)) {
            if (options.address) {
                if (this.geocodeCache[options.address]) {
                    marker.setPosition(this.geocodeCache[options.address]);
                } else {
                    this.geocoder.geocode({ address: options.address }, function(results, status) {
                        if (status == 'OK') {
                            this.geocodeCache[options.address] = results[0].geometry.location;
                            marker.setPosition(this.geocodeCache[options.address]);
                            marker.setVisible(true);
                            localStorage.geocodeCache = JSON.stringify(this.geocodeCache);
                        } else {
                            console.warn('Geocode was not successful for the following reason: ' + status)
                        }
                    }.bind(this));
                }
            } else {
                // Do not add?
                //return marker;
                marker.setVisible(false);
            }
        }

        if (this.map && !this.props['enable-clusters']) {
            marker.setMap(this.map);
        }

        // events
        marker.addListener('click', this.onMarkerClick.bind(this, marker));
        marker.addListener('position_changed', this.onMarkerPositionChanged.bind(this, marker));

        this.markers.push(marker);

        return marker;
    },

    removeAllMarkers: function() {
        if (this.cluster) {
            this.cluster.clearMarkers();
        }

        this.markers = this.markers.filter(function(marker) {
            if (!marker.static) {
                google.maps.event.clearInstanceListeners(marker);
                marker.setMap(null);
                return false;
            }

            return true;
        });
    },

    getMarkerTypes: function() {
        var markerUrl = 'https://maps.google.com/mapfiles/';
        var iconsUrl = 'https://maps.google.com/intl/en_us/mapfiles/ms/micons/';

        this.markerTypes = {
            black: markerUrl + 'marker_black.png',
            grey: markerUrl + 'marker_grey.png',
            orange: markerUrl + 'marker_orange.png',
            white: markerUrl + 'marker_white.png',
            yellow: markerUrl + 'marker_yellow.png',
            purple: markerUrl + 'marker_purple.png',
            green: markerUrl + 'marker_green.png',
            start: markerUrl + 'dd-start.png',
            end: markerUrl + 'dd-end.png',
            arrow: markerUrl + 'arrow.png',
            tree: iconsUrl + 'tree.png',
            lodging: iconsUrl + 'lodging.png',
            bar: iconsUrl + 'bar.png',
            restaurant: iconsUrl + 'restaurant.png',
            horsebackriding: iconsUrl + 'horsebackriding.png',
            convienancestore: iconsUrl + 'convienancestore.png',
            hiker: iconsUrl + 'hiker.png',
            swimming: iconsUrl + 'swimming.png',
            fishing: iconsUrl + 'fishing.png',
            golfer: iconsUrl + 'golfer.png',
            sportvenue: iconsUrl + 'sportvenue.png'
        }
    }

});
